<template>
  <div>
    <!--    导航栏-->
    <tabs :height="height" @parentEvent="btn"></tabs>
    <div class="section">
      <img src="../assets/1.png" alt="" />
    </div>
    <!--   end 导航栏-->
    <!-- <full-page :options="options" ref="fullpage"> -->
    <!-- <div class="section" id="page1">
        <div class="imgs" ref="img1"> -->
    <!--            <div class="solution-banner-container">-->
    <!--              <div class="page-content"></div>-->
    <!--            </div>-->
    <!-- <el-carousel height="450px" :interval="4000">
            <el-carousel-item v-for="(item, i) in imgList" :key="i">
              <img :src="item.img" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div> -->
    <!-- </div> -->
    <!--主要内容-->
    <!-- <div class="section">
        <div class="muni">
          <div class="content">
            <div class="title">我们的服务</div>
            <div class="hint"></div>
            <div class="bg-img">
              <img src="../assets/1.gif" alt="" class="bg bg01" />
              <img src="../assets/2.gif" alt="" class="bg bg02" />
              <img src="../assets/3.gif" alt="" class="bg bg03" />
              <img src="../assets/4.gif" alt="" class="bg bg04" />
              <img src="../assets/5.gif" alt="" class="bg bg05" />
              <img src="../assets/6.gif" alt="" class="bg bg06" />
            </div>
          </div>
        </div> -->

    <!-- <img
            src="https://bluexii.oss-cn-shanghai.aliyuncs.com/png/07dfc86ccac413ecb1e4b54b86427e81.png"
            alt=""
            class="bg-img"
          /> -->

    <div class="section">
      <img src="../assets/2.png" alt="" />
    </div>

    <div class="section">
      <img src="../assets/3.png" alt="" @click="toConsulting" />
    </div>

    <div class="section">
      <img src="../assets/4.png" alt="" />
    </div>

    <div class="section">
      <img src="../assets/5.png" alt="" />
    </div>

    <div class="section">
      <img src="../assets/6.png" alt="" />
    </div>

    <div class="section">
      <img src="../assets/7.png" alt="" />
    </div>

    <div class="section">
      <footers id="botton"></footers>
    </div>
    <!-- </full-page> -->
  </div>

  <!--      <img src="../assets/index/1.png" ref="img1" alt="" />-->
  <!--      <img src="../assets/index/2.png" alt="" />-->
  <!--      <img src="../assets/index/3.png" alt="" />-->
  <!--      <img src="../assets/index/4.png" alt="" />-->
  <!--      <img src="../assets/index/5.png" alt="" />-->
  <!--    <img class="" src="../assets/index/6.png" alt="" id="botton">-->

  <!--    </div>-->

  <!--        中间部分-->
  <!--    <div class="brain">-->
  <!--    </div>-->
  <!--        end 中间部分-->
  <!-- </div> -->
</template>

<script>
import tabs from "@/components/tabs";
import footers from "@/components/footers";

export default {
  name: "index",
  props: [],
  components: {
    tabs,
    footers
  },

  data() {
    return {
      imgList: [
        {
          img: "../assets/7.png"
        },
        {
          img: "../assets/6.png"
        }
      ],
      height: 0
      // options: {
      //   //整屏插件vue-fullpage选项

      //   licenseKey: "null",
      //   // navigation: true,
      //   verticalCentered: false, //定义每一页的内容是否垂直居中
      //   afterLoad: this.afterLoad, //滚动到某一屏后的回调函数
      //   scrollOverflow: false, //内容超过满屏后显示滚动条
      //   //页面滚动速度
      //   scrollingSpeed: 300,
      //   controlArrows: true, //是否通过箭头控制slide幻灯片
      //   autoScrolling: true,
      //   loopHorizontal: false, //slide幻灯片是否循环滚动
      //   scrollBar: true, //true则是一滚动就是一整屏
      //   touchSensitivity: 5,
      //   onLeave: this.onLeave //滚动前的回调函数，
      // }
    };
  },
  created() {},
  mounted() {
    // 延迟获取高度
    setTimeout(() => {
      this.$nextTick(() => {
        var a = this.$refs.img1.clientHeight;
        this.height = a - 400;
        // console.log(this.height);
      });
    }, 500);
  },
  methods: {
    // 跳转底部
    btn() {
      // document.documentElement.scrollTop= this.height;//不需要加单位
      document.getElementById("botton").scrollIntoView();
    },
    toConsulting() {
      document.getElementById("botton").scrollIntoView();
    }
  }
};
</script>

<style lang="less" scoped>
.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

.section {
  height: 100% !important;
}

.section:nth-child(1) {
  height: 450px !important;
}

.section:last-child {
  height: 411px !important;
}

.muni {
  background: #f2f3f5;
  height: 668px;

  .content {
    width: 1200px;
    margin: 0 auto;

    .title {
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      padding-top: 65px;
      padding-bottom: 5px;
      text-align: center;
    }

    .hint {
      width: 53px;
      height: 6px;
      background: #007aff;
      margin: 0 auto;
    }

    .bg-img {
      margin-top: 56px;
      position: relative;
      // background-image: url("https://bluexii.oss-cn-shanghai.aliyuncs.com/png/3a6874818cc80053381eee59bb12f6ab.png");
      background-size: cover;
      height: 432px;
      background-position: center;

      .bg {
        height: 60px;
        width: 60px;
      }

      .bg01 {
        position: absolute;
        left: 148px;
        top: 0px;
      }

      .bg02 {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0px;
      }

      .bg03 {
        position: absolute;
        right: 155px;
        top: 0px;
      }

      .bg04 {
        position: absolute;
        left: 148px;
        bottom: 118px;
      }

      .bg05 {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 118px;
      }

      .bg06 {
        position: absolute;
        right: 148px;
        bottom: 118px;
      }
    }
  }
}

/deep/.el-carousel__item.is-animating {
  transition: transform 1s ease-in-out;
}

.solution-banner-container {
  min-height: 450px;
  background-color: #000;
  background-position: center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-image: url("https://bluexii.oss-cn-shanghai.aliyuncs.com/png/ea26938a4583e9deb0cf5f0ca1e29602.png");

  .solution-banner-content {
    margin: auto;
    padding-top: 110px;
    color: #fff;
    width: 780px;

    .solution-banner-title {
      font-size: 32px;
      line-height: 60px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    .solution-banner-desc {
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 30px;
    }
  }

  a {
    background-color: #fff;
    color: #0e57a2;
    border: 1px solid #fff;
  }
}

img {
  width: 100%;
  height: 100%;

  display: flex;
  object-fit: cover;
}

//.button{
//  display: inline-block;
//  width: 180px;
//  height: 46px;
//  line-height: 46px;
//  border-radius: 1px;
//  font-size: 16px;
//  text-align: center;
//  transition: all .1s linear 0s;
//}
</style>
